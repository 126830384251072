.appLoader {
  position: relative;
  width: 60px;
  height: 60px;
  display: inline-flex;
  background-color: transparent;
  border: 5px solid #eceff1;
  border-radius: 50%;
}
.appLoader:after {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  padding: 3px;
  border-width: 2px;
  border-style: solid;
  border-color: #7986cb transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  animation: ringrotate-loader 0.8s infinite ease-in-out;
  -webkit-animation: ringrotate-loader 0.8s infinite ease-in-out;
}

/* Animation */
@keyframes ringrotate-loader {
  0% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
  }
}
@-webkit-keyframes ringrotate-loader {
  0% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
  }
}
